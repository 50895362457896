import React from "react";
import Frame, { appendCommonArgs } from "./Frame";
import { OrganizationContext } from "../common/contexts/organization";
import { gql, useQuery } from "@apollo/client";
import { and, whereNotDeleted, whereOrganization } from "../common/utils/graphql";
import useApi from "../common/hooks/useApi";
import { DroneStatus } from "../common/grpc/ui_pb";
import useInterval from "../hooks/useInterval";

const FlyingFrame = () => {
  const [ droneStatuses, setDroneStatuses ] = React.useState<DroneStatus.AsObject[]>([]);
  const [ collapsed, setCollapsed ] = React.useState(true);
  const [ ticker, setTicker ] = React.useState<number>(0);
  const [ userCollapsed, setUserCollapsed ] = React.useState<boolean | null>(null);

  const organizationCtx = React.useContext(OrganizationContext);

  const { get } = useApi();

  const { loading, error, data } = useQuery(gql(`
    query DroneNames {
      drones(where: `+and(whereNotDeleted(), whereOrganization(organizationCtx?.organizationID))+`, order_by: {created_at: desc}) {
        id
        uuid
        name
      }
    }
  `));

  useInterval(() => {
    setTicker(ticker + 1);
  }, 1000);

  React.useEffect(() => {
    if (!data) return

    let droneUUIDs = data.drones.map((d: any) => d.uuid) as any[];
    Promise.all(droneUUIDs.map((uuid: any) => {
      return get(`/drones/${uuid}/status`);
    })).then((statuses) => {
      setDroneStatuses(statuses as DroneStatus.AsObject[]);
    });
  }, [data, ticker, get]);

  React.useEffect(() => {
    const connectedDrones = droneStatuses.filter((status) => status.metrics?.state+'' !== 'UNKNOWN');
    const newCollapsed = connectedDrones.length === 0;
    if (collapsed !== newCollapsed) {
      setCollapsed(newCollapsed);
    }
  }, [droneStatuses]);

  if (loading || error) {
    return null;
  }

  if (collapsed) {
    return null;
  }

  return (
    <Frame
      url={"https://controller.dromt.it"}
      collapsible
      collapsed={(collapsed || userCollapsed) ?? false}
      setCollapsed={setUserCollapsed}
      style={{
        position: "absolute",
        bottom: 20,
        left: 20,
        width: 300,
        height: 200,
        maxWidth: "calc(100% - 40px)",
        maxHeight: "calc(100% - 40px)",
        zIndex: 10000,
        borderRadius: 10,
        overflow: "hidden",
        boxShadow: "0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)",
      }}
    />
  );
};

export default FlyingFrame;
