import React from "react";
import { pdfjs, Document, Page } from 'react-pdf';
import type { PDFDocumentProxy } from 'pdfjs-dist';
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import { AppBar, IconButton, TextField, Toolbar, Typography } from "@mui/material";

import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import { Learnable, learnables } from "../../classes/learnable";

// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//   'pdfjs-dist/build/pdf.worker.min.mjs',
//   import.meta.url,
// ).toString();

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

type PDFFile = string | File | null;

const PDFReader = () => {
  const [learnableUUID, setLearnableUUID] = React.useState<string | undefined>();
  const [learnable, setLearnable] = React.useState<Learnable | undefined>();
  const [file, setFile] = React.useState<PDFFile>(null);
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [numPages, setNumPages] = React.useState<number>();
  const [containerRef, setContainerRef] = React.useState<HTMLElement | null>(null);
  const [containerWidth, setContainerWidth] = React.useState<number>();
  const [containerHeight, setContainerHeight] = React.useState<number>();
  const [options, setOptions] = React.useState<any>({
    cMapUrl: '/cmaps/',
    standardFontDataUrl: '/standard_fonts/',
  });

  const { t } = useTranslation();
  const param = useParams();
  const location= useLocation();

  const onDocumentLoadSuccess = ({ numPages: nextNumPages }: PDFDocumentProxy): void => {
    setNumPages(nextNumPages);
  };

  const next = () => {
    if (numPages && currentPage < numPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const previous = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const goTo = (page: number) => {
    if (numPages && page > 0 && page <= numPages) {
      setCurrentPage(page);
    }
  };

  React.useEffect(() => {
    if (param.learnableUUID) {
      setLearnableUUID(param.learnableUUID);
      const l = learnables.find((l) => l.uuid === param.learnableUUID);
      setLearnable(l);
    }
  }, [param.learnableUUID]);

  React.useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const documentIndex = searchParams.get('documentIndex') ?? '0';
    if (learnable && learnable.documents && documentIndex) {
      setFile(learnable.documents[parseInt(documentIndex)].path ?? null);
    }
  }, [location, learnable]);

  React.useEffect(() => {
    if (containerRef) {
      setContainerWidth(containerRef.clientWidth);
      setContainerHeight(containerRef.clientHeight - 48);
    }
  }, [containerRef]);

  return (
    <div style={{
      width: '100%',
      height: '100%',
      overflow: 'scroll',
      overflowY: 'scroll',
      overflowX: 'scroll',
    }} ref={setContainerRef}>
      <AppBar position="sticky" color="inherit">
        <Toolbar variant="dense">
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}></Typography>
          { numPages &&
          <React.Fragment>
            <IconButton onClick={previous}>
              <ArrowBackIosOutlinedIcon />
            </IconButton>
            <Typography variant="body1" component="div">
              {t('Page')}
              <TextField
                value={currentPage}
                onChange={(event) => goTo(parseInt(event.target.value))}
                variant="standard"
                size="small"
                type="number"
                style={{
                  width: '45px',
                  marginLeft: '8px',
                  marginRight: '8px',
                }}
              />
              {t('of')} {numPages}
            </Typography>
            <IconButton onClick={next}>
              <ArrowForwardIosOutlinedIcon />
            </IconButton>
          </React.Fragment> }
        </Toolbar>
      </AppBar>
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 'calc(100% - 48px)',
        width: '100%',
      }}>
        <Document file={file} onLoadSuccess={onDocumentLoadSuccess} options={options}>
          <Page
            pageNumber={currentPage}
            height={containerHeight}
          />
        </Document>
      </div>
    </div>
  );
};

export default PDFReader;
