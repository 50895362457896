import React from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { AppBar, Box, CssBaseline, Divider, Drawer, IconButton, List, ListItem, ListItemText, ListSubheader, Toolbar, Typography, useMediaQuery, useTheme } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useScreenSize } from '../common/hooks/useScreenSize';
import AccountMenu from '../components/AccountMenu';
import FlyMenu from '../components/FlyMenu';
import AgriMenu from '../components/AgriMenu';
import PhotogrammetryMenu from '../components/PhotogrammetryMenu';
import useIframe from '../hooks/useIframe';
import PilotMenu from '../components/PilotMenu';
import { useTranslation } from 'react-i18next';
import { version } from '../config';
import OrganizationMenu from '../components/OrganizationMenu';
import BotMenu from '../components/BotMenu';
import FlyingFrame from '../components/FlyingFrame';
import LearnMenu from '../components/LearnMenu';

interface Group {
  name: string;
  prefix: string;
  active?: boolean;
  url?: string;
  render: (key: number, active?: boolean, iframeLocation?: string) => React.ReactNode;
}

const groups = [
  { name: 'Pilot', prefix: '/pilot',
    render: (key: number, active?: boolean, _?: string) => <PilotMenu open={active} key={key} url='/pilot/' /> },
  { name: 'Learn', prefix: '/learn',
    render: (key: number, active?: boolean, _?: string) => <LearnMenu open={active} key={key} url='/learn/' /> },
  { name: 'Flight', prefix: '/fly',
    render: (key: number, active?: boolean, _?: string) => <FlyMenu open={active} key={key} url='/fly/' /> },
];

const analysisGroups = [
  { name: 'Agriculture', prefix: '/agriculture',
    render: (key: number, active?: boolean, iframeLocation?: string) => <AgriMenu open={active} key={key} url='/agriculture/' iframeLocation={iframeLocation} /> },
  // { name: 'Photogrammetry', prefix: '/photogrammetry',
  //   render: (key: number, active?: boolean, iframeLocation?: string) => <PhotogrammetryMenu open={active} key={key} url='/photogrammetry/' iframeLocation={iframeLocation} /> },
];

function Layout() {
  const { medium } = useScreenSize();

  const [ leftMenuOpen, setLeftMenuOpen ] = React.useState<boolean>(medium);
  const [ menuGroups, setMenuGroups ] = React.useState<Group[]>(groups);
  const [ analysisMenuGroups, setAnalysisMenuGroups ] = React.useState<Group[]>(analysisGroups);
  const [ hubActive, setHubActive ] = React.useState<boolean>(false);

  const location = useLocation();
  const { location: iframeLocation } = useIframe();

  const { t } = useTranslation();

  const drawerWidth = 240;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  React.useEffect(() => {
    const grps = groups.map((group): Group => {
      return {
        ...group,
        active: location.pathname.startsWith(group.prefix),
      };
    });
    setMenuGroups(grps);

    const analysisGrps = analysisGroups.map((group): Group => {
      return {
        ...group,
        active: location.pathname.startsWith(group.prefix),
      };
    });
    setAnalysisMenuGroups(analysisGrps);

    if (location.pathname === '/') {
      setHubActive(true);
    } else {
      setHubActive(false);
    }
  }, [location]);

  return (
    <React.Fragment>
      <Box sx={{ display: 'flex', height: '100%', width: '100%', overflow: 'hidden' }}>
        <CssBaseline />
        <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
          <Toolbar>
            { !medium && <IconButton
              onClick={() => {setLeftMenuOpen(!leftMenuOpen)}}
              size="large"
              edge="start"
              color="inherit"
              aria-label="open drawer"
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton> }
            <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
              <img src="/logotipo_white.png" alt="logo" style={{
                height: 32,
                width: 'auto',
              }} />
            </Typography>
            <div>
              <BotMenu />
              <OrganizationMenu />
              <AccountMenu />
            </div>
          </Toolbar>
        </AppBar>
        <Drawer
          variant={medium ? 'permanent' : 'temporary'}
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
          }}
          open={leftMenuOpen}
          onClose={() => setLeftMenuOpen(false)}
        >
          <Toolbar />
          <Box sx={{ overflow: 'auto' }}>
            <List>
              <Link to="/"><ListSubheader style={{
                margin: 8,
                marginBottom: hubActive ? 8 : 0,
                marginTop: hubActive ? 8 : 0,
                borderRadius: 12,
                backgroundColor: hubActive ? theme.palette.primary.main : 'inherit',
                color: hubActive ? theme.palette.primary.contrastText : 'inherit',
                paddingRight: hubActive ? 8 : 0,
                width: 'calc(100% - 16px)',
              }}>
                <div style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}>
                  {t('User Guide')}
                </div>
              </ListSubheader></Link>
            </List>
            <Divider />
            { menuGroups.map((group, index) => {
              return group.render(index, group.active, iframeLocation);
            }) }
            <Divider />
            <ListSubheader style={{
              textTransform: 'uppercase',
              fontWeight: 'bold',
              fontSize: '0.8rem',
              lineHeight: 2,
              paddingTop: 8,
              marginLeft: 8,
            }}>
              {t('Analysis')}
            </ListSubheader>
            { analysisMenuGroups.map((group, index) => {
              return group.render(index, group.active, iframeLocation);
            }) }
            <Divider />
            <List
              sx={{ width: '100%', maxWidth: 270, bgcolor: 'background.paper' }}
              component="nav"
            >
              <ListItem alignItems="flex-start" style={{
                alignItems: 'center',
              }}>
                <ListItemText secondary={t("version") + " " + version} />
              </ListItem>
            </List>
          </Box>
        </Drawer>
        <Box component="main" sx={{
          flexGrow: 1,
          p: 3,
          height: '100%',
          overflow: 'hidden',
          padding: isMobile ? 0 : '24px',
          borderRadius: isMobile ? 0 : '15px'
        }}>
          <Toolbar />
          <Outlet />
        </Box>
      </Box>

      <FlyingFrame />
    </React.Fragment>
  );
}

export default Layout;
