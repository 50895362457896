import React from "react";
import { useTranslation } from "react-i18next";
import { useScreenSize } from "../common/hooks/useScreenSize";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import theme from "../theme";

export const itemStyle = {
  marginLeft: 8,
  paddingLeft: 10,
  borderRadius: 12,
  borderTopRightRadius: 0,
  borderBottomRightRadius: 0,
  borderRight: `1px solid lightgray`,
};

export const selectedStyle = () => {
  return {
    ...itemStyle,
    borderRight: `2px solid ${theme.palette.primary.main}`,
  };
};

const videoIds = [
  { id: "KHx_hXy-CEM", title: "Platform Tutorial" },
];

const Dashboard = () => {
  const [ smallParent, setSmallParent ] = React.useState(false);
  const [ selectedVideo, setSelectedVideo ] = React.useState(videoIds[0].id);

  const { vertical } = useScreenSize();
  const { t } = useTranslation();

  React.useEffect(() => {
    setSmallParent(vertical);
  }, [vertical]);

  return (
    <div style={{
      display: 'flex',
      flexDirection: smallParent ? 'column' : 'row',
      height: '100%',
      width: '100%',
      overflow: 'scroll',
    }}>
      <div style={{
        width: smallParent ? '100%' : '80%',
        padding: '12px',
        paddingLeft: smallParent ? '20px' : '0',
      }}>
        <div style={{ marginTop: '0', position: 'relative', paddingBottom: '56.25%', height: 0 }}>
          <iframe
            src={`https://www.youtube.com/embed/${selectedVideo}`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', borderRadius: '15px' }}
          ></iframe>
        </div>
      </div>
      <div style={{
        width: smallParent ? '100%' : '20%',
        padding: '12px',
        paddingRight: smallParent ? '12px' : '0',
      }}>
        <List component="nav" style={{
          cursor: 'pointer',
          paddingLeft: 0,
          paddingRight: 0,
        }}>
          {videoIds.map(video => (
            <React.Fragment key={video.id}>
              <ListItem disablePadding>
                <ListItemButton selected={selectedVideo === video.id}
                  style={selectedVideo === video.id ? selectedStyle() : itemStyle}
                  onClick={() => setSelectedVideo(video.id)}>
                  <ListItemText primary={t(video.title)} />
                </ListItemButton>
              </ListItem>
            </React.Fragment>
          ))}
        </List>
      </div>
    </div>
  );
};

export default Dashboard;
