import React from "react";
import { useLocation } from "react-router-dom";
import theme from "../theme";
import Menu, { MenuItems } from "./Menu";

const items: MenuItems[] = [
  {
    items: [
      { title: 'Profile' },
      { title: 'Licenses' },
      { title: 'Fleet', url: '/pilot/fleet' },
      { title: 'Resources' },
      { title: 'Download App', url: '/pilot/download' },
    ]
  }
];

export interface PilotMenuProps {
  open?: boolean;
  url?: string;
};

const PilotMenu = (props: PilotMenuProps) => {
  const location = useLocation();

  React.useEffect(() => {
    items[0].items.forEach(item => {
      item.active = !!item.url && location.pathname.startsWith(item.url);
    });
  }, [location]);

  return (
    <Menu
      theme={theme}
      open={props.open ?? false}
      title="Pilot"
      items={items}
      url={props.url} />
  );
};

export default PilotMenu;
