import { AppBar, Backdrop, IconButton, Menu, MenuItem, Toolbar, Typography } from "@mui/material";
import React from "react";
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import Frame from "./Frame";
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from "react-i18next";

const BotMenu = () => {
  const [ pageOpen, setPageOpen ] = React.useState(false);
  const [ menuOpen, setMenuOpen ] = React.useState(false);
  const [ anchorEl, setAnchorEl ] = React.useState<null | HTMLElement>(null);

  const { t } = useTranslation();

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setMenuOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setMenuOpen(false);
    setAnchorEl(null);
  };

  const handlePage = () => {
    handleCloseMenu();
    setPageOpen(true);
  }

  const handleClosePage = () => {
    setPageOpen(false);
  }

  return (
    <React.Fragment>
      <IconButton
        size="large"
        aria-controls={menuOpen ? 'menu-bot' : undefined}
        aria-haspopup="true"
        aria-expanded={menuOpen ? 'true' : undefined}
        onClick={handleMenu}
        color="inherit"
      >
        <SupportAgentIcon />
      </IconButton>
      <Menu
        id="menu-bot"
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleCloseMenu}
        MenuListProps={{
          'aria-labelledby': 'button',
        }}
        slotProps={{
          paper: {
            style: {
              maxHeight: '60vh',
            }
          }
        }}
      >
        <MenuItem onClick={handlePage} disabled>
          {t('Platform Usage')}
        </MenuItem>
        <MenuItem onClick={handlePage} disabled>
          {t('Drone Patents')}
        </MenuItem>
        <MenuItem onClick={handlePage}>
          {t('Drone Usage')}
        </MenuItem>
      </Menu>

      <Backdrop
        sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
        open={pageOpen}
        onClick={handleClosePage}
      >
        <div style={{
          position: 'fixed',
          bottom: 20,
          right: 20,
          width: 600,
          maxWidth: 'calc(100% - 40px)',
          height: 650,
          maxHeight: 'calc(100% - 100px)',
          backgroundColor: 'white',
          borderRadius: 12,
          boxShadow: '0 0 10px rgba(0,0,0,0.1)',
          overflow: 'hidden',
        }}>
          <AppBar position="static">
            <Toolbar variant="dense">
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                {t('Chat')}
              </Typography>
              <IconButton
                size="large"
                onClick={handleClosePage}
                color="inherit"
              >
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>

          <div style={{
            height: 'calc(100% - 45px)',
          }}>
            <Frame url="https://regulation.chat.dromt.it" hideButtons />
          </div>
        </div>
      </Backdrop>
    </React.Fragment>
  );
};

export default BotMenu;
