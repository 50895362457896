import React from "react";
import { OrganizationContext } from "../common/contexts/organization";
import { gql, useQuery } from "@apollo/client";
import { and, whereNotDeleted, whereOrganization } from "../common/utils/graphql";
import Loading from "../common/components/Loading";
import EnhancedTable from "../components/EnhancedTable";
import { Button, Typography } from "@mui/material";
import { darkGray } from "../theme";
import Flight from "../classes/flight";
import { useNavigate } from "react-router-dom";
import Field from "../classes/field";
import FlightPlan from "../classes/flightPlan";
import { useTranslation } from "react-i18next";

const headCells = [
  { id: 'date', numeric: false, disablePadding: false, label: 'Date' },
  { id: 'flight_plan_name', numeric: false, disablePadding: false, label: 'FlightPlan' },
  { id: 'field_name', numeric: false, disablePadding: false, label: 'Field' },
  { id: 'drone_name', numeric: false, disablePadding: false, label: 'Drone' },
  { id: 'duration', numeric: false, disablePadding: false, label: 'Duration' },
  { id: 'state', numeric: false, disablePadding: false, label: 'State' },
  { id: 'media_number', numeric: true, disablePadding: false, label: 'Media' },
  { id: 'menu', numeric: false, disablePadding: false, label: '', unsortable: true },
];

const Reports = () => {
  const [flights, setFlights] = React.useState([]);

  const organizationCtx = React.useContext(OrganizationContext);

  const navigate = useNavigate();
  const { t } = useTranslation();

  const { loading, error, data } = useQuery(gql(`
    query Flights {
      flights(where: `+and(whereNotDeleted(), whereOrganization(organizationCtx?.organizationID))+`, order_by: {created_at: desc}) {
        id
        uuid
        created_at
        started_at
        ended_at
        completed
        current_command_index
        drone {
          uuid
          name
          deleted_at
        }
        flight_plan {
          uuid
          name
          created_at
          updated_at
          deleted_at
          field {
            id
            uuid
            name
            created_at
            updated_at
          }
        }
        field {
          id
          uuid
          name
        }
        media_aggregate(where: `+and(whereNotDeleted())+`) {
          aggregate {
            count
          }
        }
      }
    }
  `));

  React.useEffect(() => {
    if (data) {
      setFlights(data.flights.map((f: any) => {
        const fl = new Flight(
          f.id,
          f.uuid,
          f.created_at,
          f.flight_plan && f.flight_plan.deleted_at === null ? new FlightPlan(
            f.flight_plan.uuid,
            f.flight_plan.name,
            f.flight_plan.created_at,
            f.flight_plan.updated_at,
            f.flight_plan.field ? new Field(
              f.flight_plan.field.id,
              f.flight_plan.field.uuid,
              f.flight_plan.field.name,
              f.flight_plan.field.created_at,
              f.flight_plan.field.updated_at,
            ) : undefined,
          ) : undefined,
          f.field ?? undefined,
          {
            started_at: f.started_at,
            ended_at: f.ended_at,
            completed: f.completed,
            current_command_index: f.current_command_index,
          },
          {
            count: f.media_aggregate.aggregate.count,
          },
          f.drone && f.drone.deleted_at === null ? {
            uuid: f.drone.uuid,
            name: f.drone.name,
          } : undefined,
        );
        fl.navigate = navigate;
        return fl;
      }));
    }
  }, [data]);

  if (loading && !data) {
    return <Loading open />;
  }

  return (
    <React.Fragment>
      { flights.length > 0
      ?
      <EnhancedTable
        title="Reports"
        headCells={headCells}
        rows={flights} />
      :
      <React.Fragment>
        <Typography variant="h5" style={{
          textAlign: 'center',
          marginTop: '32px',
          textTransform: 'uppercase',
          color: darkGray,
        }}>
          {t('No flights found')}
        </Typography>
        <Button
          variant="text"
          color="primary"
          onClick={() => window.location.reload()}
          style={{
            display: 'block',
            margin: '32px auto',
          }}>
          {t('Reload')}
        </Button>
      </React.Fragment>
      }
    </React.Fragment>
  );
};

export default Reports;
