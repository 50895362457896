import React from "react";
import { useLocation } from "react-router-dom";
import theme from "../theme";
import Menu, { MenuItems } from "./Menu";

const items: MenuItems[] = [
  {
    items: [
      //{ title: 'Missions', url: '/fly/missions' },
      //{ title: 'Areas', url: '/fly/areas' },
      { title: 'Fleet', url: '/fly/fleet' },
      { title: 'Flight Plans', url: '/fly/plans' },
      { title: 'Missions', url: '/fly/reports' },
      //{ title: 'Organizations', url: '/fly/organizations' },
      //{ title: 'Media' },
    ]
  }
];

export interface FlyMenuProps {
  open?: boolean;
  url?: string;
};

const FlyMenu = (props: FlyMenuProps) => {
  const location = useLocation();

  React.useEffect(() => {
    items[0].items.forEach(item => {
      item.active = !!item.url && location.pathname.startsWith(item.url);
    });
  }, [location]);

  return (
    <Menu
      theme={theme}
      open={props.open ?? false}
      title="Flight"
      items={items}
      url={props.url} />
  );
};

export default FlyMenu;
