export interface Document {
  name: string;
  path?: string;
};

export interface Learnable {
  uuid: string;
  name: string;
  updated_at: string;

  cover?: string;
  documents?: Document[];
};

export const learnables = [
  { uuid: '1', name: 'OPEN A1-A3', updated_at: '2021-01-01', documents: [
    { name: 'Theory Manual', path: '/pdf/Manuale di teoria A1-A3.pdf' },
    { name: 'Exercises', path: '/pdf/Eserciziario_A1-A3.pdf' },
  ], cover: '/imgs/learn/A1-A3.png' },
  { uuid: '2', name: 'OPEN A2', updated_at: '2021-01-02', documents: [
    { name: 'Theory Manual', path: undefined },
    { name: 'Exercises', path: undefined },
  ], cover: '/imgs/learn/A2.png' },
];
