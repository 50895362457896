import { Autocomplete, Box, Dialog, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";
import React from "react";
import Drone, { FlightPlan } from "../../classes/drone";
import { gql, useQuery } from "@apollo/client";
import { and, whereNotDeleted, whereOrganization } from "../../common/utils/graphql";
import { OrganizationContext } from "../../common/contexts/organization";
import { useTranslation } from "react-i18next";
import Flight from "../../classes/flight";
import Field from "../../classes/field";

export interface LinkFieldDialogProps {
  flight: Flight;
  open: boolean;
  onClose: (field?: Field) => void;
}

const LinkFieldDialog = (props: LinkFieldDialogProps) => {
  const { open, onClose } = props;

  const [ inputValue, setInputValue ] = React.useState<string>('');
  const [ fields, setFields ] = React.useState<Field[]>([]);

  const { t } = useTranslation();

  const organizationCtx = React.useContext(OrganizationContext);

  const { loading, error, data } = useQuery(gql(`
    query FieldNames {
      fields(where: `+and(whereNotDeleted(), whereOrganization(organizationCtx?.organizationID))+`, order_by: {name: asc}) {
        id
        uuid
        name
      }
    }
  `));

  const onInputChange = React.useCallback((event: React.FormEvent<HTMLInputElement>) => {
    setInputValue((event.target as HTMLInputElement).value);
  }, []);

  const handleSuggestionClick = React.useCallback((field: Field) => {
    props.onClose(field);
  }, []);

  React.useEffect(() => {
    if (data) {
      setFields(data.fields);
    }
  }, [data]);

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={() => onClose()}
    >
      <DialogTitle>{t('Link Field')}</DialogTitle>
      <DialogContent>
        <Autocomplete
          fullWidth
          options={fields}
          autoHighlight
          getOptionLabel={(option) => option.name}
          renderOption={(props, option) => {
            const { key, ...other } = props as any;

            return (
              <Box
                key={option.uuid}
                component="li"
                {...other}
                onClick={() => handleSuggestionClick(option)}
              >
                {option.name}
              </Box>
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              autoFocus
              required
              margin="dense"
              id="field"
              name="field"
              label={t("Field")}
              type="text"
              fullWidth
              variant="standard"
              value={inputValue}
              onChange={(e) => {
                setInputValue(e.target.value);
              }}
              onInput={onInputChange}
            />
          )}
          />
      </DialogContent>
    </Dialog>
  );
};

export default LinkFieldDialog;
